import React, { Component } from 'react';
import { graphql } from 'gatsby';
import ReactFullpage from '@fullpage/react-fullpage';

import { PageHelmet } from '../components/PageHelmet';
import ImageTextSideBySide
  from '../components/SectionBlocks/RepeatableSections/ImageTextSideBySide';
import Footer from '../components/Footer';
import EventLogo from '../components/Event/EventLogo';
import Paragraph from '../components/Atoms/Paragraph';
import Button from '../components/Atoms/Button';
import CustomLink from '../components/Atoms/CustomLink';
import { pageUrls } from '../config/pageSetting';

// ********************************* UI Template (Shared with CMS) *********************************
export class EventPageTemplate extends Component {
  render() {
    const {
      title, description, sections, logos, cms = false,
    } = this.props;
    return (
      <div>
        <PageHelmet title={title} description={description} />
        <ReactFullpage
          licenseKey="B22064FC-896D48BC-A3073618-5D5858E3"
          scrollingSpeed={600}
          responsiveWidth={992}
          responsiveHeight={900}
          recordHistory={false}
          render={({ state, fullpageApi }) => (
            <ReactFullpage.Wrapper>
              {sections.map((section, i) => (
                <ImageTextSideBySide
                  key={section.title}
                  img={section.bgImg}
                  title={section.title}
                  imageOnRight={i % 2 !== 0}
                  noEffect={i === 0}
                  textAlign={['center', 'center', 'center', 'left']}
                >
                  <>
                    <Paragraph pb={5}>{section.desc}</Paragraph>
                    {section.link && (
                      <CustomLink to={pageUrls.enquiry.url}>
                        <Button width={240} text="makeEnquiry" />
                      </CustomLink>
                    )}
                    {section.pdfLink && (
                      <CustomLink to={section.pdfLink.publicURL} openNewTab>
                        <Button width={240} text="seeMenu" />
                      </CustomLink>
                    )}
                  </>
                </ImageTextSideBySide>
              ))}
              <EventLogo logos={logos} />
              <Footer pathname="/events" />
            </ReactFullpage.Wrapper>
          )}
        />
      </div>
    );
  }
}

// ********************************* Render page *********************************
const EventPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return <EventPageTemplate {...frontmatter} />;
};

export default EventPage;

// ********************************* Data graphql *********************************
export const pageQuery = graphql`
  query EventPageWithId($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        description
        sections {
          bgImg {
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 1024, quality: 90) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
          title
          desc
          link
          pdfLink {
            publicURL
          }
        }
        logos {
          icon {
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 1024, quality: 90) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
              extension
              publicURL
            }
          }
        }
      }
    }
  }
`;
