import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import Section from '../Atoms/Section';
import Container from '../Atoms/Container';
import Row from '../Atoms/Row';
import Column from '../Atoms/Column';
import Heading from '../Atoms/Heading';
import FlexBox from '../Atoms/FlexBox';
import Box from '../Atoms/Box';
import PreviewCompatibleImage from '../Atoms/PreviewCompatibleImage';
import ImageTextSideBySide
  from '../SectionBlocks/RepeatableSections/ImageTextSideBySide';

function EventLogo(props) {
  const { logos } = props;

  return (
    <Section
      pt={[6, 6, 6, 7]}
      px={[4, 4, 4, 6]}
      height={['auto', 'auto', 'auto', '100vh']}
      minHeight={['auto', 'auto', 'auto', 900]}
      position="relative"
      maxHeight={1500}
      backgroundColor="#F3F3F0"
    >
      <Container height={['auto', 'auto', 'auto', '100%']} fluid>
        <Row height="100%">
          <Column>
            <FlexBox width="100%" height="100%" flexDirection="column">
              <Heading
                h={2}
                fontSize={[2, 2, 3]}
                pb={[4, 4, 6]}
                width="100%"
                textAlign={['center', 'center', 'center', 'left']}
              >
                <FormattedHTMLMessage id="event:logo.title" defaultMessage="Our Clients" />
              </Heading>
              <FlexBox justifyContent="space-evenly">
                {logos && logos.map(l => (
                  <Box flex="0 0 120px" m={3} opacity={0.6} key={l.icon.alt}>
                    <PreviewCompatibleImage imageInfo={l.icon} />
                  </Box>
                ))}
              </FlexBox>
            </FlexBox>
          </Column>
        </Row>
      </Container>
    </Section>
  );
}

export default EventLogo;
