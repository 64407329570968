import React, { Component } from 'react';
import get from 'lodash/get';
import Reveal from 'react-reveal/Reveal';

import Section from '../../Atoms/Section';
import Container from '../../Atoms/Container';
import Column from '../../Atoms/Column';
import Heading from '../../Atoms/Heading';
import Box from '../../Atoms/Box';
import Row from '../../Atoms/Row';
import FlexBox from '../../Atoms/FlexBox';

import Gold from '../../../assets/icons/gold-angle.svg';
import RevealParagraph from '../../Atoms/RevealParagraph';
import PreviewCompatibleImage from '../../Atoms/PreviewCompatibleImage';
import theme from '../../../styles/theme';
import GoldTriangle from '../../../assets/icons/home-gold-triangle.svg';

class ImageTextSideBySide extends Component {
  render() {
    const {
      imageOnRight, goldAtTop, title, desc, img, iconOnImage, children, noEffect, withTriangle, ...rest
    } = this.props;

    return (
      <Section
        pt={0}
        pb={withTriangle ? 3 : 0}
        px={[0, 0, 0, 6]}
        height={['auto', 'auto', 'auto', '100vh']}
        position="relative"
        maxHeight={1500}
        minHeight={['auto', 'auto', 'auto', 900]}
        {...rest}
      >
        <Box
          overflowHidden
          height={['auto', 'auto', 'auto', '100%']}
          width={['100%', '100%', '100%', '50%']}
          position={['relative', 'relative', 'relative', 'absolute']}
          posAb
          top={0}
          left={imageOnRight ? 'auto' : 0}
          right={imageOnRight ? 0 : 'auto'}
        >
          <Box
            display={['none', 'none', 'none', 'block']}
            width="100%"
            height="100%"
            backgroundColor={theme.color.gold}
            backgroundImage={`url(${get(
              img,
              'image.childImageSharp.fluid.src',
              get(img, 'image', ''),
            )})`}
            backgroundSize="cover"
            backgroundPosition="center"
          />
          <Box
            posRe
            backgroundColor={theme.color.gold}
            display={['block', 'block', 'block', 'none']}
            height="100vw"
            maxHeight={600}
          >
            <PreviewCompatibleImage imageInfo={img} />
            {iconOnImage && (
            <FlexBox posAb width="100%" height="100%" top={0}>
              <Box width={['50%', '40%']}>
                <PreviewCompatibleImage imageInfo={iconOnImage} />
              </Box>
            </FlexBox>
            )}
          </Box>
        </Box>
        <Container height={['auto', 'auto', 'auto', '100%']} fluid>
          <Row height="100%">
            <Column
              col={`is-half-desktop ${imageOnRight ? '' : 'is-offset-6-desktop'}`}
              order={1}
              pl={imageOnRight ? [6, 6, 7, 0] : [6, 6, 7, 7, 8]}
              pr={imageOnRight ? [6, 6, 7, 7, 8] : [6, 6, 7, 0]}
              py={[7, 7, 7, 5]}
            >
              <FlexBox width="100%" height="100%">
                <Box>
                  {title && (
                    <>
                      {noEffect && (
                      <Heading h={1} pb={[4, 4, 5]} width="100%">
                        {title}
                      </Heading>
                      )}
                      {!noEffect && (
                      <Reveal duration={1000} effect="section-title-ani">
                        <Heading h={1} pb={[4, 4, 5]} width="100%">
                          {title}
                        </Heading>
                      </Reveal>
                      )}
                    </>
                  )}
                  <RevealParagraph noEffect={noEffect} desc={desc} />
                  {children && !noEffect && (
                  <Reveal duration={1000} effect="section-content-ani">
                    {children}
                  </Reveal>
                  )}
                  {children && noEffect && (
                  <>
                    {children}
                  </>
                  )}
                </Box>
              </FlexBox>
            </Column>
          </Row>
        </Container>
        <Box
          display={['none', 'none', 'none', 'block']}
          width={120}
          height={120}
          posAb
          left="calc(50% - 60px)"
          bottom={goldAtTop ? '80%' : '-60px'}
          zIndex={1}
        >
          <Reveal duration={500} effect="gold-angle">
            <Gold />
          </Reveal>
        </Box>
        {withTriangle && (
        <Box posAb bottom={0} left={0} lineHeight={0} width={[70, 70, 100, 250]}>
          <GoldTriangle />
        </Box>
        )}
      </Section>
    );
  }
}

export default ImageTextSideBySide;
