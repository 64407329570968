import React, { Fragment } from 'react';
import Reveal from 'react-reveal/Reveal';
import Paragraph from './Paragraph';

function RevealParagraph(props) {
  const { desc, noEffect } = props;
  const descArr = desc ? desc.split(/\r?\n/) : [];

  if (noEffect) {
    return (
      <>
        {descArr
          && descArr.map((sub, i) => (
            <Fragment key={i}>
              {!sub && <Paragraph />}
              {sub && sub !== '' && <Paragraph pb={0}>{sub}</Paragraph>}
            </Fragment>
          ))}
      </>
    );
  }

  return (
    <>
      {descArr
        && descArr.map((sub, i) => (
          <Reveal key={i} duration={1000} effect="section-content-ani">
            <>
              {!sub && <Paragraph />}
              {sub && sub !== '' && <Paragraph pb={0}>{sub}</Paragraph>}
            </>
          </Reveal>
        ))}
    </>
  );
}

export default RevealParagraph;
